/* Style all font awesome icons */
.fa {
    padding: 8px 0px;
    margin-bottom: 20px;
    margin-left: 10px;
    font-size: 30px;
    width: 50px;
    text-align: center;
    text-decoration: none;
    border-radius: 50%;
}


.fa:hover {
    opacity: 0.7;
}


.fa-facebook {
    background: #3B5998;
    color: white;
}

.fa-google {
    background: #dd4b39;
    color: white;
}

.fa-linkedin {
    background: #007bb5;
    color: white;
}

.fa-instagram {
    background: #125688;
    color: white;
}
